import { Access, useCreateRoleMutation } from "@/graphql/types";
import { useToast } from "primevue/usetoast";
import { useValidation } from "vue3-form-validation";

export const useCreateRole = () => {
  const toast = useToast();

  const { form, hasError, validateFields, resetFields } = useValidation({
    name: {
      $value: "",
      $rules: [(n: string) => !n && ""],
    },
  });

  const { loading, mutate, onError, onDone } = useCreateRoleMutation({
    update: (cache, { data }) => {
      if (data?.createRole) {
        cache.modify({
          fields: {
            roles(value, { toReference }) {
              return [toReference(data.createRole), ...value];
            },
          },
        });
      }
    },
  });

  onDone(({ data }) => {
    if (data?.createRole) {
      toast.add({
        summary: "Creation de rôle",
        severity: "success",
        detail: "Rôle crée avec succès !",
        life: 2500,
      });
      resetFields();
    } else {
      toast.add({
        summary: "Creation de rôle",
        severity: "warn",
        detail: "Une erreur est survenue! Réessayez plus tard !",
        life: 2500,
      });
    }
  });

  onError(() => {
    toast.add({
      summary: "Creation de rôle",
      severity: "error",
      detail: "Une erreur est survenue! Réessayez plus tard !",
      life: 2500,
    });
  });

  const submit = () => {
    validateFields()
      .then((input) => {
        void mutate({ input });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return {
    loading,
    submit,
    form,
    hasError,
  };
};
