import { useRemoveRoleMutation } from "@/graphql/types";
import { Reference } from "@apollo/client";
import { PrimeIcons } from "primevue/api";
import { useConfirm } from "primevue/useconfirm";
import { useToast } from "primevue/usetoast";

export const useRemoveRole = () => {
  const confirm = useConfirm();
  const toast = useToast();

  const {
    loading: removeLoading,
    mutate,
    onDone,
    onError,
  } = useRemoveRoleMutation({
    update: (cache, { data }) => {
      const id = data?.removeRole;
      if (id) {
        cache.modify({
          fields: {
            roles(value, { readField }) {
              return value.filter(
                (ref: Reference) => readField("id", ref) !== id
              );
            },
          },
        });
      }
    },
  });

  onDone(({ data }) => {
    if (data?.removeRole) {
      toast.add({
        summary: "Suppression",
        detail: "Rôle supprimé avec succès",
        life: 2500,
        severity: "success",
      });
    } else {
      toast.add({
        summary: "Suppression",
        detail: "Une erreur est survenue ! Vous pouvez réessayer plus tard !",
        life: 2500,
        severity: "info",
      });
    }
  });

  onError(() => {
    toast.add({
      summary: "Suppression",
      detail: "Une erreur est survenue ! Vous pouvez réessayer plus tard !",
      life: 2500,
      severity: "info",
    });
  });

  const handleRemove = (event: any, id: number) => {
    event.preventDefault();
    confirm.require({
      message: "Etes-vous sûr de vouloir retirer ce rôle ?",
      accept: () => void mutate({ id }),
      icon: PrimeIcons.INFO_CIRCLE,
      rejectClass: "p-button-secondary p-button-outlined p-button-sm",
      acceptClass: "p-button-sm p-button-danger",
      acceptLabel: "Confirmer",
      rejectLabel: "Annuler",
      target: event.currentTarget,
    });
  };

  return {
    handleRemove,
    removeLoading,
  };
};
